.chat {
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 900px;
  padding: 16px;
  margin: auto;
}

.chat-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
}

.message {
  margin-bottom: 30px;
  width: max-content;
  border: 1px solid grey;
  padding: 10px;
  border-radius: 5px;
  max-width: 80%;
  white-space: pre-line;
}

.message-form {
  display: flex;
  margin-top: 8px;
  max-width: 90%;
}

.message-form input {
  flex-grow: 1;
  padding: 8px;
  margin-right: 8px;
}

button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.message-bot {
  align-self: flex-end;
  margin-bottom: 30px;
  width: max-content;
  border: 1px solid grey;
  padding: 10px;
  border-radius: 5px;
  max-width: 80%;
  white-space: pre-line;
}
